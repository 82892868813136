<template>
  <main class="supplier-payment supplier-payment-1013 page_bg_grey">
    <section class="payment-caption-section">
      <div class="payment-caption-container">
        <div class="payment-caption-wrapper payment-product-name">
          <span>{{ $t("Purchase a power charging code") }}</span>
        </div>

        <div class="payment-caption-wrapper text-center">
          <img
            class="supplier-logo"
            src="/assets/img/logo/n-logo.png"
          />
        </div>

        <div class="payment-caption-wrapper">&nbsp;</div>
      </div>
    </section>

    <section class="payment-form-section">
      <form class="payment-form payment-1013">
        <div class="payment_field_wrapper">
          <label class="label payment_field_label" for>{{ $t("Contract number") }}</label>
          <input
            id="contractNumber"
            class="input"
            type="tel"
            v-model="contractNumber"
            v-model.lazy="$v.contractNumber.$model"
            :class="{ 'validation-error': $v.contractNumber.$error }"
          />
          <div v-if="$v.contractNumber.$error" class="color-red2 text-bold">
              <p v-if="!$v.contractNumber.required">{{ $t("This is a required field.") }}</p>
          </div>
        </div>

        <div class="payment_field_wrapper">
          <label class="label payment_field_label" for>{{ $t("Invoice sum") }}</label>
          <input
            id="sumToPay"
            class="input w-100"
            type="number"
            step="1"
            min="0"
            v-model="sumToPay"
            v-model.lazy="$v.sumToPay.$model"
            :class="{ 'validation-error': $v.sumToPay.$error }"
            @blur="$v.price.$touch()"
          />
          <div v-if="$v.sumToPay.$error" class="color-red2 text-bold">
            <p v-if="!$v.sumToPay.required">{{ $t("This is a required field.") }}</p>
            <p v-else-if="!$v.sumToPay.minValue">{{ $t("Min value is {value}", {value: $v.sumToPay.$params.minValue.min}) }}</p>
            <p v-else-if="!$v.sumToPay.maxPaymentSum">{{ $t("Max value is {value}", {value: $v.sumToPay.$params.maxPaymentSum.maxPaymentSum}) }}</p>
          </div>
        </div>

        <div class="payment_field_wrapper">
          <label class="label payment_field_label" for>{{ $t("Payment sum") }}</label>
          <input
            id="price"
            class="input w-100"
            type="number"
            step="1"
            min="0"
            disabled
            v-model="price"
            v-model.lazy="$v.price.$model"
            :class="{ 'validation-error': $v.price.$error }"
          />
          <p class="color-red2">
            {{ $t('** Commission for this payment in the system is [commission_amount] NIS').indexOf('[commission_amount]') !== -1 ? $t('** Commission for this payment in the system is [commission_amount] NIS').replace('[commission_amount]', finalCommission) : $t('** Commission for this payment in the system is [commission_amount] NIS') + ' ' + finalCommission }}
          </p>
          <div v-if="$v.price.$error" class="color-red2 text-bold">
            <p v-if="!$v.price.required">{{ $t("This is a required field.") }}</p>
            <p v-else-if="!$v.price.integer">{{ $t("Value should be integer") }}</p>
            <p v-else-if="!$v.price.minPaymentSum">{{ $t("Min value is {value}", {value: $v.price.$params.minPaymentSum.minPaymentSum}) }}</p>
            <p v-else-if="!$v.price.maxPaymentSum">{{ $t("Max value is {value}", {value: $v.price.$params.maxPaymentSum.maxPaymentSum}) }}</p>
            <p v-else-if="!$v.price.balanceCheck">{{ $t("Current balance is {value}", {value: $v.price.$params.balanceCheck.balance}) }}</p>
          </div>
        </div>

        <div class="payment_field_wrapper">
          <label class="label payment_field_label" for>{{ $t("Pay") }}</label>
          <input
            id="phoneNumber"
            class="input"
            type="tel"
            v-model="phoneNumber"
            v-model.lazy="$v.phoneNumber.$model"
            :class="{ 'validation-error': $v.phoneNumber.$error }"
          />
          <div v-if="$v.phoneNumber.$error" class="color-red2 text-bold">
            <p v-if="!$v.phoneNumber.required">{{ $t("This is a required field.") }}</p>
            <p v-else-if="!$v.phoneNumber.exactLength">{{ $t("Value length should be {value}", {value: $v.phoneNumber.$params.exactLength.length}) }}</p>
          </div>
        </div>

        <a class="link payment-button" @click="handleSupplierPaymentSubmit">{{ $t("Whole") }}</a>
      </form>
      <div class="payment-form-section-bottom-block mt-10">
        <span class="payment-form-section-bottom-block-text">{{ $t("At the end of the payment need to give client receipt") }}</span>
      </div>
      
    </section>
  </main>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import appConfig from "../../../appConfig";
import { validationMixin } from "vuelidate";
import {
  required,
  minValue,
  maxValue,
  integer
} from "vuelidate/lib/validators";
import { helpers } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "SupplierPayment1013",
  props: {
    msg: String,
  },
  data: () => {
    return {
      contractNumber: "",
      price: "",
      phoneNumber: "",
      sumToPay: "",
      error: 0,
      minIECPrice: 60,
    };
  },
  validations() {
    return {
      contractNumber: {
        required
      },
      sumToPay: {
        required,
        minValue: minValue(this.minIECPrice),
        maxPaymentSum: this.maxPaymentSumValidation(this.maxPaymentSum - this.finalCommission),
      },
      price: {
        required,
        integer,
        minPaymentSum: this.minPaymentSumValidation(this.minIECPrice + this.finalCommission),
        maxPaymentSum: this.maxPaymentSumValidation(this.maxPaymentSum),
        balanceCheck: this.balanceCheckValidation(this.balanceCreditBalanceValue),
      },
      phoneNumber: {
        required,
        exactLength: this.exactLengthValidation(10),
      },
      error: {
        maxValue: maxValue(0)
      }
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      user_balance: state => state.account.user_details && state.account.user_details.hasOwnProperty('balance') ? state.account.user_details.balance : null,
      supplier_items: (state) => state.supplier.supplier_items,
      suppliers: (state) => state.supplier.suppliers,
      suppliers_products: (state) => state.supplier.suppliers_products,
      current_payment_data: (state) =>
        state.supplier_payment.current_payment_data,
    }),
    balanceCreditBalanceValue() {
      return this.user_balance && Array.isArray(this.user_balance) && 2 in this.user_balance && 'value' in this.user_balance[2] ? this.user_balance[2].value : 0;
    },
    supplierId() {
      return this.$route.params.supplier_id;
    },
    supplierData() {
      let supplierDataFromSuppliersItemsData = null;
      if (this.supplier_items) {
        for (let supplier_type in this.supplier_items) {
          for (let suppliers_type_item of this.supplier_items[supplier_type]) {
            if (suppliers_type_item.id == this.supplierId) {
              supplierDataFromSuppliersItemsData = { ...suppliers_type_item };
              break;
            }
          }
        }
      }

      let supplierDataFromSuppliersData = null;
      if (this.suppliers && this.supplierId in this.suppliers) {
        supplierDataFromSuppliersData = { ...this.suppliers[this.supplierId] };
      }

      let supplierData = supplierDataFromSuppliersData
        ? supplierDataFromSuppliersData
        : supplierDataFromSuppliersItemsData;
      return supplierData ? supplierData : null;
    },
    supplierProductsAll() {
      let supplierProducts = [];
      if (
        this.suppliers_products &&
        this.supplierId in this.suppliers_products
      ) {
        let supplerProductsByType = this.suppliers_products[this.supplierId];

        if (supplerProductsByType) {
          for (let supplier_product_type in supplerProductsByType) {
            for (let supplier_product of supplerProductsByType[
              supplier_product_type
            ]) {
              supplierProducts.push(supplier_product);
            }
          }
        }
      }

      return supplierProducts ? supplierProducts : [];
    },
    productId() {
      let productId = null;

      if (this.productData && "id" in this.productData && this.productData.id) {
        productId = this.productData.id;
      }

      return productId ? productId : null;
    },
    productData() {
      let productData = null;

      if (
        this.supplierProductsAll &&
        this.supplierProductsAll.length &&
        "0" in this.supplierProductsAll &&
        this.supplierProductsAll[0]
      ) {
        productData = this.supplierProductsAll[0];
      }

      return productData ? productData : null;
    },
    finalCommission() {
      let finalCommission = 0;

      if (
        this.productData &&
        "final_commission" in this.productData &&
        this.productData.final_commission
      ) {
        finalCommission = this.productData.final_commission;
      }

      return finalCommission ? finalCommission : 0;
    },
    maxPaymentSum() {
      let maxPayment = 0;

      if (
        this.productData &&
        "max_payment" in this.productData &&
        this.productData.max_payment
      ) {
        maxPayment = this.productData.max_payment;
      }

      return maxPayment ? maxPayment : 0;
    },
  },
  watch: {
    sumToPay(sumToPay) {
      if (!this.isNumber(sumToPay) || !parseFloat(sumToPay)) {
        sumToPay = "";
      }
      sumToPay = parseFloat(sumToPay);
      this.price =
        sumToPay +
        (this.finalCommission &&
        this.isNumber(this.finalCommission) &&
        this.finalCommission > 0 &&
        parseFloat(this.finalCommission)
          ? parseFloat(this.finalCommission)
          : 0);
    },
  },
  created() {
    this.setPaymentData();
  },
  methods: {
    ...mapMutations("supplier_payment", {
      setCurrentPaymentData: "setCurrentPaymentData",
    }),
    ...mapActions('supplier_payment', {
      getSupplierPaymentDetails: 'getSupplierPaymentDetails'
    }),
    getApiURL() {
      return appConfig.apiUrl;
    },
    async handleSupplierPaymentSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const supplierId = this.supplierId;
      const itemId = this.productId;
      let contractNumber = this.contractNumber;
      let phoneNumber = this.phoneNumber;
      let sumToPay = this.sumToPay;
      let price = this.price;
      const finalCommission = this.finalCommission;

      if (supplierId != '' && itemId != '' && contractNumber != ''
        && phoneNumber != '' && sumToPay > 0) {
        await this.getSupplierPaymentDetails(
            {
                supplier_id: supplierId,
                payment_data: {
                    supplierId: supplierId,
                    itemId: itemId,
                    billNum: contractNumber,
                    billNumber: contractNumber,
                    price: sumToPay,
                    phoneNumber: phoneNumber
                }
            }
        )
          .then(
            result_data => {
                if (result_data && result_data.hasOwnProperty('success') && result_data.success
                    && result_data.success.hasOwnProperty('price')) {
                    this.sumToPay = parseFloat(result_data.success.price);

                    if (result_data.success.hasOwnProperty('address')) {
                        this.address = result_data.success.address;
                    }

                    if (result_data.success.hasOwnProperty('contract_number')) {
                        this.contractNumber = result_data.success.contract_number;
                    }

                    if (result_data.success.hasOwnProperty('meter_number')) {
                        this.meter_number = result_data.success.meter_number;
                    }

                    if (result_data.success.hasOwnProperty('net_amount')) {
                        this.net_amount = result_data.success.net_amount;
                    }

                    if (result_data.success.hasOwnProperty('reduced_amount')) {
                        this.reduced_amount = result_data.success.reduced_amount;
                    }

                    if (result_data.success.hasOwnProperty('purchased_amount')) {
                        this.purchased_amount = result_data.success.purchased_amount;
                    }

                    if (result_data.success.hasOwnProperty('uuid')) {
                        this.uuid = result_data.success.uuid;
                    }
                } else {
                    this.error = 1;
                }

            },
            error => {
                this.error = 1;
            }
          );
      }

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.error = 0;
        return;
      }

      price = this.price;
      sumToPay = this.sumToPay;
      contractNumber = this.contractNumber;
      phoneNumber = this.phoneNumber;
      const address = this.address;
      const meter_number = this.meter_number;
      const purchased_amount = this.purchased_amount;
      const net_amount = this.net_amount;
      const reduced_amount = this.reduced_amount;
      const uuid = this.uuid;

      if (supplierId != '' && itemId != '' && contractNumber != ''
        && sumToPay != '' && price != '' && phoneNumber != '' && uuid != '') {
        this.setCurrentPaymentData(
          {
            supplier_id: supplierId,
            payment_data: {
              supplierId: supplierId,
              itemId: itemId,
              contractNumber: contractNumber,
              price: price,
              sumToPay: sumToPay,
              phoneNumber: phoneNumber,
              address: address,
              finalCommission: finalCommission,
              purchased_amount: purchased_amount,
              net_amount: net_amount,
              reduced_amount: reduced_amount,
              uuid: uuid,
              meter_number: meter_number
            }
          }
        )
        this.$router.push({name: 'SupplierPaymentConfirmation', params: {supplier_id: this.supplierId}})
      }
    },
    isNumber: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    maxPaymentSumValidation(maxPaymentSum) {
      return helpers.withParams(
        { type: "maxPaymentSum", maxPaymentSum },
        function (value) {
          return (
            parseFloat(value) &&
            parseFloat(maxPaymentSum) &&
            parseFloat(value) <= parseFloat(maxPaymentSum)
          );
        }
      );
    },
    balanceCheckValidation(balance) {
      return helpers.withParams(
        { type: "balanceCheck", balance },
        function (value) {
          return (
            parseFloat(value) &&
            parseFloat(balance) &&
            parseFloat(value) <= parseFloat(balance)
          );
        }
      );
    },
    minPaymentSumValidation(minPaymentSum) {
      return helpers.withParams(
        { type: "minPaymentSum", minPaymentSum },
        function (value) {
          return (
            parseFloat(value) &&
            parseFloat(minPaymentSum) &&
            parseFloat(value) >= parseFloat(minPaymentSum)
          );
        }
      );
    },
    exactLengthValidation(length) {
      return helpers.withParams(
        { type: "exactLength", length},
        function (value) {
          return (
            value &&
            length &&
            value.toString().length === parseInt(length)
          );
        }
      );
    },
    setPaymentData() {
      let paymentData =
        this.current_payment_data &&
        this.supplierId in this.current_payment_data
          ? this.current_payment_data[this.supplierId]
          : null;

      if (paymentData) {
        this.contractNumber = paymentData.hasOwnProperty("contractNumber")
          ? paymentData.contractNumber
          : "";
        this.price = paymentData.hasOwnProperty("price")
          ? paymentData.price
          : "";
        this.phoneNumber = paymentData.hasOwnProperty("phoneNumber")
          ? paymentData.phoneNumber
          : "";
        this.sumToPay = paymentData.hasOwnProperty("sumToPay")
          ? paymentData.sumToPay
          : "";
      }
    },
  },
  updated: function () {},
};
</script>
<style lang="scss" scoped>
    .supplier-payment-1013 {
        .supplier-logo {
            max-width: 100px;
        }
        .payment-product-name,
        .payment-form-section-bottom-block-text {
            font-size: 30px;
        }
    }
    .ltr-type {
        .payment-form {
           text-align: left; 
        }
        .input-tooltip-container {
            left: unset;
            right: 0;
        }
        .payment-form-with-tooltips .payment_field_wrapper .input {
            margin-left: 0;
            margin-right: 12px;
        }
    }
</style>