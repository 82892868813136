<template>
  <main class="supplier-payment supplier-payment-193 page_bg_grey">
    <section class="payment-caption-section">
      <div class="payment-caption-container">
        <div class="payment-caption-wrapper payment-product-name">
          <span>{{ $t("Payment to authorities") }}</span>
        </div>

        <div class="payment-caption-wrapper text-center">
          <img
            class="supplier-logo"
            src="/assets/img/logo/monicipal-logo.png"
          />
        </div>

        <div class="payment-caption-wrapper">&nbsp;</div>
      </div>
    </section>

    <section class="payment-form-section">
      <form class="payment-form payment-193">
        <div class="payment_field_wrapper">
          <label class="label payment_field_label" for>{{ $t("Select permission") }}</label>
          <vue-select 
            :options="payment.options" 
            class="input cus-select"
            v-model="payment.selected"
          ></vue-select>
        </div>
        <div class="payment_field_wrapper">
          <label class="label payment_field_label" for>{{ $t("Type of payment in the authority") }}</label>
          <vue-select 
            :options="payment.options" 
            class="input cus-select"
            v-model="payment.selected"
          ></vue-select>
        </div>

        <a class="link payment-button" @click="handleSupplierPaymentSubmit">{{ $t("Whole") }}</a>
      </form>

      <div class="payment-form-section-bottom-block">
        <span class="payment-form-section-bottom-block-text">{{ $t("At the end of the payment need to give client receipt") }}</span>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "SupplierPayment193",
  data() {
    return {
      payment: {
        options: [],
        selected: ""
      },
    }
  }
};
</script>
<style lang="scss" scoped>
    .supplier-payment-193 {
      .payment-caption-container {
        align-items: center;
        .payment-caption-wrapper {
          margin-top: 0;
        }
        .supplier-logo {
          max-width: 90px;
          min-width: unset;
        }
        .payment-product-name {
          font-size: 30px;
        }
      }
    }
    .ltr-type {
      .payment-form {
        text-align: left; 
      }
    }
</style>