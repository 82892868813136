<template>
    <main class="supplier-payment supplier-payment-185 page_bg_grey">

        <section class="payment-caption-section">

            <div class="payment-caption-container">

                <div class="payment-caption-wrapper payment-product-name">
                    <span v-if="supplierData && supplierData.name">{{ supplierData.name }}</span>
                </div>

                <div class="payment-caption-wrapper text-center">
                    <img class="supplier-logo" v-if="supplierData && supplierData.image" :src="supplierData.image" />
                </div>

                <div class="payment-caption-wrapper">
                    &nbsp;
                </div>

            </div>

        </section>

        <section class="payment-form-section">

            <form class="payment-form payment-form-2-columns payment-185">

                <div class="payment-form-column">

                    <div class="payment_field_wrapper">
                        <label class="label payment_field_label" for="">
                            מספר חוזה
                        </label>
                        <input id="contractNumber"
                               class="input"
                               type="tel"
                               v-model="contractNumber"
                               v-model.lazy="$v.contractNumber.$model"
                               :class="{ 'validation-error': $v.contractNumber.$error || $v.hasOwnProperty('price') && $v.price.$error }">
                    </div>

                    <div class="payment_field_wrapper">
                        <label class="label payment_field_label" for="">
                            מספר טלפון נייד
                        </label>
                        <input id="phoneNumber"
                               class="input"
                               type="tel"
                               v-model="phoneNumber"
                               v-model.lazy="$v.phoneNumber.$model"
                               :class="{ 'validation-error': $v.phoneNumber.$error }">
                    </div>

                    <a class="link payment-button" @click="handleSupplierPaymentSubmit">שלם</a>

                </div>

                <div class="payment-form-column">
                    <div class="form-description-img-wrapper">
                        <img class="form-description-img" src="/assets/img/electricity_payment_description.png"/>
                    </div>
                </div>

            </form>

            <div class="payment-form-section-bottom-block">

                <div class="payment-form-section-bottom-block-text"><span class="red-text">שימו לב!! לפי הנחיות חברת החשמל, אין לחייב את הלקוח בעמלה</span></div>

                <div class="payment-form-section-bottom-block-text">בסיום התשלום צריך לספק ללקוח קבלה בלבד!</div>

            </div>

        </section>

    </main>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex'
    import appConfig from '../../../appConfig'
    import { validationMixin } from 'vuelidate'
    import { required, minLength, maxLength, minValue, maxValue } from 'vuelidate/lib/validators'
    import { helpers } from 'vuelidate/lib/validators'

    export default {
        mixins: [validationMixin],
        name: 'SupplierPayment185',
        props: {
            msg: String
        },
        data: () => {
            return {
                contractNumber: '',
                price: '',
                phoneNumber: '',
                address: '',
                invoice: '',
                name: '',
                error: 0,
                validatePrice: false
            }
        },
        validations() {
            let validations_config = {
                contractNumber: {
                    required
                },
                phoneNumber: {
                    required,
                    minLength: minLength(10),
                    maxLength: maxLength(10)
                },
                error: {
                    maxValue: maxValue(0)
                }
            }

            if (this.validatePrice) {
                validations_config.price = {
                    required,
                    minValue: minValue(0),
                    maxPaymentSum: this.maxPaymentSumValidation(this.maxPaymentSum)
                }
            }

            return validations_config
        },
        mounted() {
        },
        computed: {
            ...mapState({
                supplier_items: state => state.supplier.supplier_items,
                suppliers: state => state.supplier.suppliers,
                suppliers_products: state => state.supplier.suppliers_products,
                current_payment_data: state => state.supplier_payment.current_payment_data
            }),
            supplierId() {
                return this.$route.params.supplier_id
            },
            supplierData() {
                let supplierDataFromSuppliersItemsData = null;
                if (this.supplier_items) {
                    for (let supplier_type in this.supplier_items) {
                        for (let suppliers_type_item of this.supplier_items[supplier_type]) {
                            if (suppliers_type_item.id == this.supplierId) {
                                supplierDataFromSuppliersItemsData = {...suppliers_type_item};
                                break;
                            }
                        }
                    }
                }

                let supplierDataFromSuppliersData = null;
                if (this.suppliers && this.supplierId in this.suppliers) {
                    supplierDataFromSuppliersData = {...this.suppliers[this.supplierId]}
                }

                let supplierData = supplierDataFromSuppliersData ? supplierDataFromSuppliersData : supplierDataFromSuppliersItemsData
                return supplierData ? supplierData : null
            },
            supplierProductsAll() {
                let supplierProducts = []
                if (this.suppliers_products && this.supplierId in this.suppliers_products) {
                    let supplerProductsByType = this.suppliers_products[this.supplierId]

                    if (supplerProductsByType) {
                        for (let supplier_product_type in supplerProductsByType) {
                            for (let supplier_product of supplerProductsByType[supplier_product_type]) {
                                supplierProducts.push(supplier_product)
                            }
                        }
                    }
                }

                return supplierProducts ? supplierProducts : []
            },
            productId() {
                let productId = null

                if (this.productData && 'id' in this.productData && this.productData.id) {
                    productId = this.productData.id
                }

                return productId ? productId : null
            },
            productData() {
                let productData = null

                if (this.supplierProductsAll && this.supplierProductsAll.length
                    && '0' in this.supplierProductsAll && this.supplierProductsAll[0]) {
                    productData = this.supplierProductsAll[0]
                }

                return productData ? productData : null
            },
            maxPaymentSum() {
                let maxPayment = 0

                if (this.productData && 'max_payment' in this.productData && this.productData.max_payment) {
                    maxPayment = this.productData.max_payment
                }

                return maxPayment ? maxPayment : 0
            }
        },
        watch: {
            contractNumber(contractNumber) {
                this.validatePrice = false
            }
        },
        created() {
            this.setPaymentData()
        },
        methods: {
            ...mapMutations('supplier_payment', {
                setCurrentPaymentData: 'setCurrentPaymentData'
            }),
            ...mapActions('supplier_payment', {
                getSupplierPaymentDetails: 'getSupplierPaymentDetails'
            }),
            getApiURL() {
                return appConfig.apiUrl
            },
            async handleSupplierPaymentSubmit() {
                this.validatePrice = false
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }

                const supplierId = this.supplierId
                const itemId = this.productId
                const contractNumber = this.contractNumber
                const phoneNumber = this.phoneNumber

                if (supplierId != '' && itemId != '' && contractNumber != ''
                    && phoneNumber != '') {
                    await this.getSupplierPaymentDetails(
                        {
                            supplier_id: supplierId,
                            payment_data: {
                                supplierId: supplierId,
                                itemId: itemId,
                                billNum: contractNumber,
                                billNumber: contractNumber,
                                phoneNumber: phoneNumber
                            }
                        }
                    )
                        .then(
                            result_data => {
                                if (result_data && result_data.hasOwnProperty('success') && result_data.success
                                    && result_data.success.hasOwnProperty('price')) {
                                    this.price = result_data.success.price

                                    if (result_data.success.hasOwnProperty('address')) {
                                        this.address = result_data.success.address
                                    }

                                    if (result_data.success.hasOwnProperty('invoice')) {
                                        this.invoice = result_data.success.invoice
                                    }

                                    if (result_data.success.hasOwnProperty('name')) {
                                        this.name = result_data.success.name
                                    }
                                } else {
                                    this.error = 1;
                                }

                            },
                            error => {
                                this.error = 1;
                            }
                        );
                }

                this.validatePrice = true
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.error = 0;
                    return;
                }

                const price = this.price
                const address = this.address
                const invoice = this.invoice
                const name = this.name

                if (supplierId != '' && itemId != '' && contractNumber != ''
                    && price != '' && phoneNumber != '') {
                    this.setCurrentPaymentData(
                        {
                            supplier_id: supplierId,
                            payment_data: {
                                supplierId: supplierId,
                                itemId: itemId,
                                contractNumber: contractNumber,
                                price: price,
                                phoneNumber: phoneNumber,
                                address: address,
                                invoice: invoice,
                                name: name
                            }
                        }
                    )
                    this.$router.push({name: 'SupplierPaymentConfirmation', params: {supplier_id: this.supplierId}})
                }
            },
            maxPaymentSumValidation(maxPaymentSum) {
                return helpers.withParams(
                    {type: 'maxPaymentSum', maxPaymentSum},
                    function(value) {
                        return parseFloat(value) && parseFloat(maxPaymentSum) && parseFloat(value) <= parseFloat(maxPaymentSum)
                    }
                )
            },
            setPaymentData(){
                let paymentData = this.current_payment_data && (this.supplierId in this.current_payment_data) ? this.current_payment_data[this.supplierId] : null

                if (paymentData) {

                    this.contractNumber = paymentData.hasOwnProperty('contractNumber') ? paymentData.contractNumber : ''
                    this.price = paymentData.hasOwnProperty('price') ? paymentData.price : ''
                    this.phoneNumber = paymentData.hasOwnProperty('phoneNumber') ? paymentData.phoneNumber : ''
                    this.address = paymentData.hasOwnProperty('address') ? paymentData.address : ''
                    this.invoice = paymentData.hasOwnProperty('invoice') ? paymentData.invoice : ''
                    this.name = paymentData.hasOwnProperty('name') ? paymentData.name : ''

                }
            }
        },
        updated: function () {
        },
    }
</script>

<style>
</style>