<template>
  <main class="supplier-payment supplier-payment-1019 page_bg_grey">
    <section class="payment-caption-section">
      <div class="payment-caption-container">
        <div class="payment-caption-wrapper payment-product-name">
          <span>{{ $t("Payment 6 crosses north") }}</span>
        </div>

        <div class="payment-caption-wrapper text-center">
          <img
            class="supplier-logo"
            src="/assets/img/logo/6North.png"
          />
        </div>

        <div class="payment-caption-wrapper d-flex justify-content-center pt-5">
        </div>
      </div>
    </section>

    <section class="payment-form-section">
      <form class="payment-form payment-form-2-columns payment-1019">
        <div class="payment-form-column form-div">
          <div class="payment_field_wrapper width-limit">
            <label class="label payment_field_label" for="carNumber">
              {{ $t("Number clearinghouse") }}
            </label>
            <input
              id="carNumber"
              class="input"
              type="text"
              v-model="carNumber"
              v-model.lazy="$v.carNumber.$model"
              :class="{ 'validation-error': $v.carNumber.$error }"
            />
          </div>
          <div class="payment_field_wrapper width-limit">
            <label class="label payment_field_label" for="license">
              {{ $t("SUPPLIER_PAYMENT.LICENSE") }}
            </label>
            <input
              id="license"
              class="input"
              type="text"
              v-model="license"
              v-model.lazy="$v.license.$model"
              :class="{ 'validation-error': $v.license.$error }"
            />
          </div>

          <div class="payment_field_wrapper width-limit">
            <label class="label payment_field_label" for="phoneNumber">{{ $t("Pay") }}</label>
            <input
                    id="phoneNumber"
                    class="input"
                    type="tel"
                    v-model="phoneNumber"
                    v-model.lazy="$v.phoneNumber.$model"
                    :class="{ 'validation-error': $v.phoneNumber.$error }"
            />
          </div>

          <a class="link payment-button width-limit" @click="handleSupplierPaymentSubmit">{{ $t("Check") }}</a>
          <div class="payment-form-section-bottom-block">
            <span class="payment-form-section-bottom-block-text">{{ $t("At the end of the payment need to give client receipt") }}</span>
          </div>
        </div>

        <div class="payment-form-column">
          <div class="form-description-img-wrapper">
            <img
              class="form-description-img"
              src="/assets/img/bill/invoice6North.png"
            />
          </div>
        </div>
      </form>
    </section>
  </main>
</template>

<script>
    import { mapState, mapActions, mapMutations } from "vuex";
    import appConfig from "../../../appConfig";
    import { validationMixin } from "vuelidate";
    import {
        required,
        minLength,
        maxLength,
        minValue,
        maxValue,
        integer
    } from "vuelidate/lib/validators";
    import { helpers } from "vuelidate/lib/validators";

    export default {
        mixins: [validationMixin],
        name: "SupplierPayment1019",
        props: {
            msg: String,
        },
        data: () => {
            return {
                carNumber: "",
                license: "",
                phoneNumber: "",
                options: ""
            };
        },
        validations() {
            return {
                carNumber: {
                    required
                },
                license: {
                    required
                },
                phoneNumber: {
                    required,
                    minLength: minLength(10),
                    maxLength: maxLength(10)
                }
            };
        },
        mounted() {},
        computed: {
            ...mapState({
                supplier_items: (state) => state.supplier.supplier_items,
                suppliers: (state) => state.supplier.suppliers,
                suppliers_products: (state) => state.supplier.suppliers_products,
                current_payment_data: (state) =>
                    state.supplier_payment.current_payment_data,
            }),
            supplierId() {
                return this.$route.params.supplier_id;
            },
            supplierData() {
                let supplierDataFromSuppliersItemsData = null;
                if (this.supplier_items) {
                    for (let supplier_type in this.supplier_items) {
                        for (let suppliers_type_item of this.supplier_items[supplier_type]) {
                            if (suppliers_type_item.id == this.supplierId) {
                                supplierDataFromSuppliersItemsData = { ...suppliers_type_item };
                                break;
                            }
                        }
                    }
                }

                let supplierDataFromSuppliersData = null;
                if (this.suppliers && this.supplierId in this.suppliers) {
                    supplierDataFromSuppliersData = { ...this.suppliers[this.supplierId] };
                }

                let supplierData = supplierDataFromSuppliersData
                    ? supplierDataFromSuppliersData
                    : supplierDataFromSuppliersItemsData;
                return supplierData ? supplierData : null;
            },
            supplierProductsAll() {
                let supplierProducts = [];
                if (
                    this.suppliers_products &&
                    this.supplierId in this.suppliers_products
                ) {
                    let supplerProductsByType = this.suppliers_products[this.supplierId];

                    if (supplerProductsByType) {
                        for (let supplier_product_type in supplerProductsByType) {
                            for (let supplier_product of supplerProductsByType[
                                supplier_product_type
                                ]) {
                                supplierProducts.push(supplier_product);
                            }
                        }
                    }
                }

                return supplierProducts ? supplierProducts : [];
            },
            productId() {
                let productId = null;

                if (this.productData && "id" in this.productData && this.productData.id) {
                    productId = this.productData.id;
                }

                return productId ? productId : null;
            },
            productData() {
                let productData = null;

                if (
                    this.supplierProductsAll &&
                    this.supplierProductsAll.length &&
                    "0" in this.supplierProductsAll &&
                    this.supplierProductsAll[0]
                ) {
                    productData = this.supplierProductsAll[0];
                }

                return productData ? productData : null;
            },
            finalCommission() {
                let finalCommission = 0;

                if (
                    this.productData &&
                    "final_commission" in this.productData &&
                    this.productData.final_commission
                ) {
                    finalCommission = this.productData.final_commission;
                }

                return finalCommission ? finalCommission : 0;
            }
        },
        created() {
            this.setPaymentData();
        },
        methods: {
            ...mapMutations("supplier_payment", {
                setCurrentPaymentData: "setCurrentPaymentData",
            }),
            ...mapActions('supplier_payment', {
                getSupplierPaymentDetails: 'getSupplierPaymentDetails'
            }),
            getApiURL() {
                return appConfig.apiUrl;
            },
            async handleSupplierPaymentSubmit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }

                const supplierId = this.supplierId;
                const itemId = this.productId;
                let carNumber = this.carNumber;
                let license = this.license;
                let phoneNumber = this.phoneNumber;
                const finalCommission = this.finalCommission;

                if (supplierId != '' && itemId != '' && carNumber != ''
                    && license != '' && phoneNumber != '') {
                    await this.getSupplierPaymentDetails(
                        {
                            supplier_id: supplierId,
                            payment_data: {
                                supplierId: supplierId,
                                itemId: itemId,
                                car_number: carNumber,
                                license: license,
                                phoneNumber: phoneNumber
                            }
                        }
                    )
                        .then(
                            result_data => {
                                if (result_data && result_data.hasOwnProperty('success') && result_data.success) {

                                    if (result_data.success.hasOwnProperty('options')
                                        && Array.isArray(result_data.success.options)
                                        && result_data.success.options.length > 0) {
                                        this.options = result_data.success.options;
                                    }

                                } else {
                                    this.error = 1;
                                }

                            },
                            error => {
                                this.error = 1;
                            }
                        );
                }

                const options = this.options;

                if (supplierId != '' && itemId != '' && carNumber != ''
                    && license != '' && phoneNumber != '' && options != '') {
                    this.setCurrentPaymentData(
                        {
                            supplier_id: supplierId,
                            payment_data: {
                                supplierId: supplierId,
                                itemId: itemId,
                                carNumber: carNumber,
                                license: license,
                                phoneNumber: phoneNumber,
                                finalCommission: finalCommission,
                                options: options
                            }
                        }
                    )
                    this.$router.push({name: 'SupplierPaymentConfirmation', params: {supplier_id: this.supplierId}})
                }
            },
            isNumber: function (n) {
                return !isNaN(parseFloat(n)) && isFinite(n);
            },
            setPaymentData() {
                let paymentData =
                    this.current_payment_data &&
                    this.supplierId in this.current_payment_data
                        ? this.current_payment_data[this.supplierId]
                        : null;

                if (paymentData) {
                    this.carNumber = paymentData.hasOwnProperty("carNumber")
                        ? paymentData.carNumber
                        : "";
                    this.license = paymentData.hasOwnProperty("license")
                        ? paymentData.license
                        : "";
                    this.phoneNumber = paymentData.hasOwnProperty("phoneNumber")
                        ? paymentData.phoneNumber
                        : "";
                }
            },
        },
        updated: function () {},
    };
</script>

<style lang="scss" scoped>
.supplier-payment-1019 {
  .payment-caption-container {
    align-items: center;
    .payment-caption-wrapper {
      margin-top: 0;
    }
    .supplier-logo {
      max-width: 100px;
      min-width: unset;
    }
    .payment-product-name {
      font-size: 30px;
    }
  }
  .form-description-img-wrapper {
    text-align: center;
    .form-description-img {
      width: 60%;
    }
  }
  .form-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    .width-limit {
      width: 80%;
    }
    .payment-button {
      margin-top: 35px;
    }
    .payment-form-section-bottom-block {
      margin-top: 20px;
      .payment-form-section-bottom-block-text {
        font-size: 28px;
      }
    }
  }
  
}
</style>