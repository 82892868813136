import { render, staticRenderFns } from "./SupplierPayment1019.vue?vue&type=template&id=985b5f94&scoped=true&"
import script from "./SupplierPayment1019.vue?vue&type=script&lang=js&"
export * from "./SupplierPayment1019.vue?vue&type=script&lang=js&"
import style0 from "./SupplierPayment1019.vue?vue&type=style&index=0&id=985b5f94&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "985b5f94",
  null
  
)

export default component.exports