<template>
    <div class="w-100 blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''" >
        <SupplierPayment187 v-if="supplierId == 187"></SupplierPayment187>
        <SupplierPayment185 v-else-if="supplierId == 185"></SupplierPayment185>
        <SupplierPayment192 v-else-if="supplierId == 192"></SupplierPayment192>
        <SupplierPayment999 v-else-if="supplierId == 999"></SupplierPayment999>
        <SupplierPayment194 v-else-if="supplierId == 194"></SupplierPayment194>
        <SupplierPayment186 v-else-if="supplierId == 186"></SupplierPayment186>
        <SupplierPayment193 v-else-if="supplierId == 193"></SupplierPayment193>
        <SupplierPayment212 v-else-if="supplierId == '212'"></SupplierPayment212>
        <SupplierPayment213 v-else-if="supplierId == '213'"></SupplierPayment213>
        <SupplierPayment1013 v-else-if="supplierId == 1013"></SupplierPayment1013>
        <SupplierPayment1019 v-else-if="supplierId == 1019"></SupplierPayment1019>
        <main v-else class="supplier-payment page_bg_grey">
            <div class="text-center">Payment Page, sorry, we are developing this page yet...</div>
        </main>

        <loading :active.sync="loading"
                 :can-cancel="false"
                 :is-full-page="true"></loading>
    </div>
</template>

<script>

    import { mapState, mapActions } from 'vuex'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import SupplierPayment187 from '../components/supplier_payments/187/SupplierPayment187'
    import SupplierPayment185 from '../components/supplier_payments/185/SupplierPayment185'
    import SupplierPayment192 from '../components/supplier_payments/192/SupplierPayment192'
    import SupplierPayment999 from '../components/supplier_payments/999/SupplierPayment999'
    import SupplierPayment194 from '../components/supplier_payments/194/SupplierPayment194'
    import SupplierPayment186 from '../components/supplier_payments/186/SupplierPayment186'
    import SupplierPayment193 from '../components/supplier_payments/193/SupplierPayment193'
    import SupplierPayment212 from '../components/supplier_payments/212/SupplierPayment212'
    import SupplierPayment213 from '../components/supplier_payments/213/SupplierPayment213'
    import SupplierPayment1013 from '../components/supplier_payments/1013/SupplierPayment1013'
    import SupplierPayment1019 from '../components/supplier_payments/1019/SupplierPayment1019'
    export default {
        name: 'SupplierPayment',
        components: {
            Loading,
            SupplierPayment187,
            SupplierPayment185,
            SupplierPayment192,
            SupplierPayment999,
            SupplierPayment194,
            SupplierPayment186,
            SupplierPayment193,
            SupplierPayment212,
            SupplierPayment213,
            SupplierPayment1013,
            SupplierPayment1019
        },
        data: () => {
            return {
                loading: false
            }
        },
        computed: {
            ...mapState({
                supplierPaymentInProgress: state => state.supplier_payment.supplierPaymentInProgress,
                getSupplierPaymentDetailsInProgress: state => state.supplier_payment.getSupplierPaymentDetailsInProgress
            }),
            supplierId() {
                return this.$route.params.supplier_id
            }
        },
        created() {
            this.getSupplier({'supplier_id': this.supplierId})
            this.getSupplierProducts({'supplier_id': this.supplierId})
        },
        mounted() {
            moduleSupplierPayments.init()
        },
        watch: {
            supplierPaymentInProgress(inProgress) {
                this.loading = inProgress || this.getSupplierPaymentDetailsInProgress;
            },
            getSupplierPaymentDetailsInProgress(inProgress) {
                this.loading = inProgress || this.supplierPaymentInProgress;
            }
        },
        methods: {
            ...mapActions('supplier', {
                getSupplier: 'getSupplier',
                getSupplierProducts: 'getSupplierProducts'
            }),
        }
    }
</script>